import App from "../layouts/App";

var indexRoutes = [
    { 
        path: "/", 
        name: "welcome", 
        component: App 
    },
];

export default indexRoutes;